import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "http://16.16.182.243"
    : "http://16.16.182.243:5000";

export default axios.create({
  baseURL: BASE_URL,
});

console.log({BASE_URL});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
